import { Injectable } from '@angular/core';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ComponentHelperService, NominationFormObject } from '@features/forms/services/component-helper/component-helper.service';
import { Column } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { FormFieldCdtService } from './form-field-cdt.service';
import { FormFieldHelperService } from './form-field-helper.service';
import { FormFieldTableAndSubsetService } from './form-field-table-and-subset.service';

@Injectable({ providedIn: 'root' })
export class FormFieldAdHocService {

  constructor (
    private i18n: I18nService,
    private formFieldCdtService: FormFieldCdtService,
    private formFieldHelperService: FormFieldHelperService,
    private componentHelper: ComponentHelperService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService
  ) { }

  /**
   * Gets the reference field column for filtering options
   *
   * @param field: reference field
   * @returns the reference field column
   */
  getRefFieldColumn (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel
  ): Column {
    const [columnDef]: AdHocReportingUI.ColumnDefinition[] = this.getReferenceFieldColumnDef({
      ...field,
      formIds: []
    });

    return {
      label: field.name,
      visible: true,
      type: columnDef.type,
      labelOnly: true,
      prop: '' + field.referenceFieldId,
      options: [],
      columnName: field.name,
      filterOnly: true,
      onSelectedAsFilter: async (column: Column) => {
        column.options = await this.formFieldCdtService.getCdtOptionsFromRefField(
          this.formFieldHelperService.referenceFieldMapById[+column.prop]
        );
      }
    };
  }

  getReferenceFieldColumnDef (
    field: ReferenceFieldAPI.ReferenceFieldAdHocModel,
    isClientSide = false,
    isNominationReportField = false
  ): AdHocReportingUI.ColumnDefinition[] {
    let base: AdHocReportingUI.ColumnDefinition = {
      i18nKey: '',
      display: field.name,
      column: isNominationReportField ? this.componentHelper.getNominatorReportKey(field.key) : field.key,
      type: 'text',
      isReferenceField: true,
      isStandardField: field.isStandardProductField,
      formIds: field.formIds,
      noFiltering: field.isEncrypted || field.isMasked || field.isRichText,
      parentBucket: isNominationReportField ? NominationFormObject : ''
    };

    if (field.customDataTableGuid) {
      const filterOptions = this.formFieldCdtService.getFilterOptions(field.customDataTableGuid);

      base = {
        ...base,
        type: field.supportsMultiple ?
          'multiValueList' :
          'multiListFuzzyText',
        filterOptions,
        format: 'label'
      } as AdHocReportingUI.TypeaheadSelectColumnDefinition;
    }

    switch (field.type) {
      default:
      case ReferenceFieldsUI.ReferenceFieldTypes.ExternalAPI:
      case ReferenceFieldsUI.ReferenceFieldTypes.TextField:
      case ReferenceFieldsUI.ReferenceFieldTypes.TextArea:
        return [{
          ...base,
          type: field.supportsMultiple ?
            'multiValueText' :
            base.type
        }] as AdHocReportingUI.DefaultColumnDefinition[];
      case ReferenceFieldsUI.ReferenceFieldTypes.Currency:
        const currencyText = this.i18n.translate('common:textCurrency', {}, 'Currency');

        const currencyValueKey = field.key + '.currencyValue';
        const currencyColumn: AdHocReportingUI.ColumnDefinition = {
          i18nKey: '',
          supportsGrouping: false,
          display: field.name + ' ' + currencyText,
          column: currencyValueKey,
          type: 'text',
          isReferenceField: true,
          isStandardField: field.isStandardProductField,
          formIds: field.formIds,
          noFiltering: field.isEncrypted || field.isMasked
        };

        return [
          {
            ...base,
            type: 'number',
            format: 'decimal'
          },
          {
            ...currencyColumn
          }
        ] as AdHocReportingUI.NumberColumnDefinition[];
      case ReferenceFieldsUI.ReferenceFieldTypes.DataPoint:
        let label = field.name;
        const relatedField = this.formFieldTableAndSubsetService.getRelatedFieldGroupOrTable(
          field.referenceFieldTableId
        );
        if (!!relatedField) {
          label = `${field.name} (${relatedField.name})`;
        }
        let format = 'number';
        let type = 'number';
        let filterOptions: TypeaheadSelectOption[];
        const collectionType = relatedField?.subsetCollectionType;
        if (collectionType === ReferenceFieldAPI.DataSetCollectionType.Percent) {
          format = 'wholeNumberPercent';
        } else if (collectionType === ReferenceFieldAPI.DataSetCollectionType.YesOrNo) {
          type ='list';
          format = 'label';
          filterOptions = [{
            label: this.i18n.translate(
              'common:textYes',
              {},
              'Yes'
            ),
            value: isClientSide ? 1 : '1'
          }, {
            label: this.i18n.translate(
              'common:textNo',
              {},
              'No'
            ),
            value: isClientSide ? 0 : '0'
          }];
        }
        const updatedDef = {
          ...base,
          display: label,
          referenceFieldTableId: field.referenceFieldTableId,
          type,
          format,
          filterOptions,
          parentTableDisplay: relatedField?.name
        } as AdHocReportingUI.SelectColumnDefinition;

        return [updatedDef];
      case ReferenceFieldsUI.ReferenceFieldTypes.Number:
      case ReferenceFieldsUI.ReferenceFieldTypes.Aggregate:
        return [{
          ...base,
          type: 'number',
          format: 'decimal'
        }] as AdHocReportingUI.NumberColumnDefinition[];
      case ReferenceFieldsUI.ReferenceFieldTypes.Radio:
      case ReferenceFieldsUI.ReferenceFieldTypes.SelectBoxes:
      case ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable:
        return [{
          ...base,
          supportsGrouping: !field.supportsMultiple && !field.isEncrypted
        }];
      case ReferenceFieldsUI.ReferenceFieldTypes.Date:
        return [{
          ...base,
          type: 'date',
          format: 'date'
        }] as AdHocReportingUI.DateColumnDefinition[];
      case ReferenceFieldsUI.ReferenceFieldTypes.FileUpload:
        return [{
          ...base,
          format: 'file'
        }] as AdHocReportingUI.FileColumnDefinition[];
      case ReferenceFieldsUI.ReferenceFieldTypes.Checkbox:
        return [{
          ...base,
          type: 'list',
          format: 'label',
          filterOptions: [{
            label: this.i18n.translate(
              'common:textIsTrue',
              {},
              'Is true'
            ),
            value: isClientSide ? true : 'true'
          }, {
            label: this.i18n.translate(
              'common:textIsFalse',
              {},
              'Is false'
            ),
            value: isClientSide ? false : 'false'
          }]
        }];
      case ReferenceFieldsUI.ReferenceFieldTypes.Address:
        const addressFields = this.getAddressFormFieldAttrs(field.name, field.captureExtendedAddressInfo);

        return addressFields.map((addressVal) => {
          return {
            i18nKey: '',
            display: addressVal.display,
            column: `${field.key}.${addressVal.attribute}`,
            type: 'text',
            isReferenceField: true,
            isStandardField: field.isStandardProductField,
            formIds: field.formIds
          };
        });
    }
  }

  getAddressFormFieldAttrs (
    addressFieldName: string,
    includeCRAFields: boolean
  ): ReferenceFieldAPI.AddressAttrInfo[] {
    const fields: ReferenceFieldAPI.AddressAttrInfo[] = [{
      display: addressFieldName + ' ' + this.i18n.translate(
        'GLOBAL:ADDRESS_ONE',
        {},
        'Address 1'
      ),
      attribute: 'address1'
    }, {
      display: addressFieldName + ' ' + this.i18n.translate(
        'GLOBAL:ADDRESS_2',
        {},
        'Address 2'
      ),
      attribute: 'address2'
    }, {
      display: addressFieldName + ' ' + this.i18n.translate(
        'GLOBAL:CITY',
        {},
        'City'
      ),
      attribute: 'city'
    }, {
      display: addressFieldName + ' ' + this.i18n.translate(
        'GLOBAL:lblPostalCode',
        {},
        'Postal code'
      ),
      attribute: 'postalCode'
    }, {
      display: addressFieldName + ' ' + this.i18n.translate(
        'GLOBAL:STATE',
        {},
        'State'
      ),
      attribute: 'stateProvRegCode'
    }, {
      display: addressFieldName + ' ' + this.i18n.translate(
        'GLOBAL:COUNTRY',
        {},
        'Country'
      ),
      attribute: 'countryCode'
    }, {
      display: addressFieldName + ' ' + this.i18n.translate(
        'common:textLatitude',
        {},
        'Latitude'
      ),
      attribute: 'lat'
    }, {
      display: addressFieldName + ' ' + this.i18n.translate(
        'common:textLongitude',
        {},
        'Longitude'
      ),
      attribute: 'lng'
    }, {
      display: addressFieldName + ' ' + this.i18n.translate(
        'common:textAddress',
        {},
        'Address'
      ),
      attribute: 'formattedAddress'
    }, includeCRAFields ? {
      display: addressFieldName + ' ' + this.i18n.translate(
        'common:textTractCode',
        {},
        'Tract code'
      ),
      attribute: 'craTractCode'
    } : undefined, includeCRAFields ? {
      display: addressFieldName + ' ' + this.i18n.translate(
        'common:textMsaCode',
        {},
        'MSA code'
      ),
      attribute: 'craMsaCode'
    } : undefined, includeCRAFields ? {
      display: addressFieldName + ' ' + this.i18n.translate(
        'common:textStateCode',
        {},
        'State code'
      ),
      attribute: 'craStateCode'
    } : undefined, includeCRAFields ? {
      display: addressFieldName + ' ' + this.i18n.translate(
        'common:textCountyCode',
        {},
        'County code'
      ),
      attribute: 'craCountyCode'
    } : undefined];

    return fields.filter((field) => !!field);
  }
}
