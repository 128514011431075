import { Injectable } from '@angular/core';
import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormAudience, FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { EmployeeSSOFieldsService } from '@features/employee-sso-fields/employee-sso-fields.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { Tab } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { get } from 'lodash';
import { ComponentHelperService, REPORT_FIELD_KEY } from '../services/component-helper/component-helper.service';
import { Advanced_Validation_Setting, Allow_Override_Calculated_Value, Allow_Recused_Settings, Api_Tab, Calculated_Value_Setting, Character_Counter_Setting, Clear_When_Hidden_Setting, Collapsed_Panel_Setting, Collapsible_Panel_Setting, Column_Properties_Settings, CompKey, ComponentConfigTabType, ComponentTabOptions, ComponentTabOptionsWithValue, ComponentTabSetting, ComponentValueType, Conditional_Logic_Setting, Custom_Currency_Settings, Custom_Error_Setting, Data_Set_Validation_Total, Decimal_Places_Setting, DefaultValType, Default_Value_Setting, Dependent_Picklist_Settings, Description_Setting, Disabled_Setting, Display_Type_Setting, Empty_State_Tab, External_Api_Data_Updates, External_Api_Form_Field_Source, External_Api_Settings, Hidden_Cdt_Keys, Hide_Label_Setting, In_Kind_Data_Settings, In_Kind_Validation_Settings, Inline_Layout_Setting, Input_Mask_Setting, Label_Setting, Legend_Setting, Max_Length_Setting, Max_Words_Setting, Min_Length_Setting, Min_Words_Setting, Num_Items_Before_Scroll_Setting, Old_Calculated_Value_Setting, Old_Conditional_Logic_Setting, Old_Custom_Validation_Setting, Placeholder_Setting, Prefix_Setting, Pull_From_Bbgm_Setting, Regular_Expression_Setting, Report_Field_Data_Tab, Require_Decimal_Setting, Require_External_Api_Response, Require_Reason_Setting, Require_Supporting_Document_Setting, Required_Setting, Rows_Per_Page_Setting, Set_Value_Tab, Suffix_Setting, Tab_Index_Setting, Table_Component_Visible_Columns, Text_Area_Rows_Setting, Text_For_Approving_Setting, Text_For_Declining_Setting, Title_Setting, Tooltip_Setting, Validation_Tab_File_Multi_Components, Validation_Tab_File_Single_Components, Validation_Tab_Number_Components, Validation_Tab_SelectBox_Components, Validation_Tab_Subset_Components, Validation_Tab_Table_Components, Visible_To_Applicants, Visible_To_Managers, Word_Counter_Setting } from './component-configuration.typing';

@Injectable({ providedIn: 'root' })
export class ComponentConfigurationService {

  constructor (
    private clientSettingsService: ClientSettingsService,
    private componentHelper: ComponentHelperService,
    private i18n: I18nService,
    private employeeSSOFieldsService: EmployeeSSOFieldsService,
    private formFieldHelperService: FormFieldHelperService
  ) { }

  /**
   *
   * @param component: component object from form definition
   * @returns tabs for the component configuration modal
   */
  getTabsForComponentConfiguration (
    component: FormDefinitionComponent,
    formAudience: FormAudience
  ): Tab[] {
    const tabs: Tab[] = [];
    const {
      config
    } = this.getComponentConfig(component, formAudience);
    Object.keys(config).forEach((key) => {
      const type: ComponentConfigTabType = +key;
      const components = config[type].components;
      if (components.length > 0) {
        tabs.push({
          label: this.getLabelByConfigTabType(type),
          context: type,
          active: false
        });
      }
    });

    tabs.forEach((tab, index) => {
      if (index === 0) {
        tab.active = true;
      }
    });

    return tabs;
  }

  /**
   *
   * @param type component tab type
   * @returns translated label
   */
  getLabelByConfigTabType (type: ComponentConfigTabType) {
    switch (type) {
      case ComponentConfigTabType.Display:
        return this.i18n.translate(
          'GLOBAL:textDetails',
          {},
          'Details'
        );
      case ComponentConfigTabType.Data:
        return this.i18n.translate(
          'GLOBAL:textData',
          {},
          'Data'
        );
      case ComponentConfigTabType.Api:
        return this.i18n.translate(
          'common:texApi',
          {},
          'API'
        );
      case ComponentConfigTabType.Calculated_Value:
        return this.i18n.translate(
          'common:hdrCalculatedValue',
          {},
          'Calculated Value'
        );
      case ComponentConfigTabType.Conditional_Visibility:
        return this.i18n.translate(
          'FORMS:textDisplay',
          {},
          'Display'
        );
      case ComponentConfigTabType.Set_Value:
        return this.i18n.translate(
          'common:hdrSetValue',
          {},
          'Set Value'
        );
      case ComponentConfigTabType.Validation:
        return this.i18n.translate(
          'common:textValidation',
          {},
          'Validation'
        );
      case ComponentConfigTabType.Columns:
        return this.i18n.translate(
          'common:textColumns',
          {},
          'Columns'
        );
      }
  }

  /**
   *
   * @param component: component object from form definitions
   * @returns configuration settings for component
   */
  getComponentConfig (
    component: FormDefinitionComponent,
    formAudience: FormAudience
  ): {
    config: Record<ComponentConfigTabType, ComponentTabOptionsWithValue>;
    modalSubHeader: string;
  } {
    const adaptedType = this.componentHelper.getAdaptedTypeFromComponentType(component.type);
    let config: Record<ComponentConfigTabType, ComponentTabOptions>;
    let modalSubHeader = '';
    switch (adaptedType) {
      case 'amountRequested':
        config = this.getAmountRequestedConfig(
          component.customConditional,
          component.validate?.custom,
          component.calculateValue
        );
        modalSubHeader = this.i18n.translate(
          'GLOBAL:lblCashAmountRequested',
          {},
          'Cash amount requested'
        );
        break;
      case 'inKindItems':
        config = this.getInKindConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'common:hdrInKindAmountRequested',
          {},
          'In kind amount requested'
        );
        break;
      case 'careOf':
        config = this.getCareOfConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'FORMS:textAttention',
          {},
          'Attention'
        );
        break;
      case 'decision':
        config = this.getDecisionConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'GLOBAL:lblDecision',
          {},
          'Decision'
        );
        break;
      case 'designation':
        config = this.getDesignationConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'GLOBAL:textDesignation',
          {},
          'Designation'
        );
        break;
      case REPORT_FIELD_KEY:
        config = this.getReportFieldConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'common:textReportField',
          {},
          'Report field'
        );
        break;
      case 'reviewerRecommendedFundingAmount':
        config = this.getRecommendedFundingConfig(
          component.customConditional,
          component.validate?.custom,
          component.calculateValue
        );
        modalSubHeader = this.i18n.translate(
          'GLOBAL:textReviewerRecommendedFundingAmount',
          {},
          'Reviewer recommended funding amount'
        );
        break;
      case 'specialHandling':
        config = this.getSpecialHandlingConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'ADMIN:hdrAlternateAddress',
          {},
          'Alternate Address'
        );
        break;
      case 'referenceField':
        config = this.getReferenceFieldConfig(
          component.type,
          component.customConditional,
          component.validate?.custom,
          component.calculateValue,
          formAudience
        );
        const field = this.formFieldHelperService.getReferenceFieldFromCompType(component.type);
        const formFieldName = this.i18n.translate(
          'common:lblFormField',
          {},
          'Form field'
        );
        modalSubHeader = `${formFieldName}: ${field.name} (${field.key})`;
        break;
      case 'employeeSSO':
        config = this.getEmployeeSsoConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'common:textEmployeeSSO',
          {},
          'Employee SSO'
        );
        const fields = this.employeeSSOFieldsService.employeeSSOFields;
        const key = component.type.split('-')[1];
        const found = fields.find((_field) => {
          return _field.attr === key;
        });
        if (!!found) {
          modalSubHeader = `${modalSubHeader} (${found.name || found.columnName})`;
        }
        break;
      case 'content':
        config = this.getContentConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'common:labelContent',
          {},
          'Content'
        );
        break;
      case 'columns':
        config = this.getColumnsConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'common:textColumns',
          {},
          'Columns'
        );
        break;
      case 'fieldset':
        config = this.getFieldSetConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'common:textFieldSet',
          {},
          'Field set'
        );
        break;
      case 'panel':
        config = this.getPanelConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'common:textPanel',
          {},
          'Panel'
        );
        break;
      case 'table':
        config = this.getTableConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'FORMS:textTable',
          {},
          'Table'
        );
        break;
      case 'well':
        config = this.getWellConfig(component.customConditional);
        modalSubHeader = this.i18n.translate(
          'common:textWell',
          {},
          'Well'
        );
        break;
    }


    const adaptedConfig = Object.keys(config).reduce((acc, key) => {
      const type = +key as ComponentConfigTabType;
      const thisConfig = config[type];

      return {
        ...acc,
        [type]: {
          ...thisConfig,
          components: thisConfig.components.map((comp) => {
            return {
              ...comp,
              value: this.getValueForComponentSetting(component, comp.key),
              typeaheadOptions: comp.selectOptions.map((opt) => {
                return {
                  label: this.i18n.translate(
                    opt.i18nKey,
                    {},
                    opt.i18nDefault
                  ),
                  value: opt.value
                };
              })
            };
          })
        }
      };
    }, {} as Record<ComponentConfigTabType, ComponentTabOptionsWithValue>);

    return {
      config: adaptedConfig,
      modalSubHeader
    };
  }

  getValueForComponentSetting (
    comp: FormDefinitionComponent,
    key: CompKey
  ): ComponentValueType {
    if (key) {
      return get(comp, key);
    }

    return null;
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns array of conditional logic components based on the customConditional existing on component
   */
  getConditionalLogicComponents (customConditional: string) {
    if (!!customConditional) {
      return [
        Old_Conditional_Logic_Setting,
        Conditional_Logic_Setting
      ];
    }

    return [
      Conditional_Logic_Setting
    ];
  }

  /**
   *
   * @param calculatedValue: old js calculated value logic stored on the component
   * @returns array of calculated value components based on the calculated value existing on component
   */
  getCalculatedValueComponents (calculatedValue: string) {
    if (!!calculatedValue) {
      return [
        Old_Calculated_Value_Setting,
        Calculated_Value_Setting
      ];
    }

    return [
      Calculated_Value_Setting
    ];
  }

  /**
   *
   * @param customValidation: old js custom validation logic stored on the component
   * @returns array of custom validation components based on the custom validation existing on component
   */
  getCustomValidationComponents (customValidation: string) {
    if (!!customValidation) {
      return [
        Old_Custom_Validation_Setting,
        Advanced_Validation_Setting
      ];
    }

    return [
      Advanced_Validation_Setting
    ];
  }

  /**
   * @param compType: comp type stored on the form definition
   * @param customConditional: old js custom conditional logic stored on the component
   * @param customValidation: old js custom validation logic stored on the component
   * @param calculatedValue: old js calculated value logic stored on the component
   * @returns config info for reference field component
   */
  getReferenceFieldConfig (
    compType: string,
    customConditional: string,
    customValidation: string,
    calculatedValue: string,
    formAudience: FormAudience
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    const field = this.formFieldHelperService.getReferenceFieldFromCompType(compType);
    const hasInternational = this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.HasInternational);

    const {
      isRadio,
      isSelectBoxes,
      isNumber,
      isTextArea,
      isTextField,
      isFileUpload,
      isTable,
      isCurrencyField,
      isSubset,
      isDependentPicklist,
      isExternalApiField,
      isAddress
    } = this.formFieldHelperService.getTypeHelpers(field);
    const {
      defaultValType,
      supportsDisabled,
      supportsFormulaBuilder,
      supportsMinMax,
      supportsPlaceholder,
      supportsSetValueTab,
      supportsTooltip,
      supportsValidationTab,
      supportsWordCount,
      supportsClearWhenHidden,
      supportsDataTab,
      supportsHideLabel
    } = this.formFieldHelperService.getEditFormSupportsSettings(field, formAudience);
    const ValidationTab = supportsValidationTab ?
      {
        components: [
          Required_Setting,
          Custom_Error_Setting
        ]
      } :
      Empty_State_Tab;
    if (supportsValidationTab) {
      if (isFileUpload && !supportsMinMax) {
        ValidationTab.components = Validation_Tab_File_Single_Components;
      }
      if (supportsMinMax) {
        if (isNumber) {
          ValidationTab.components = Validation_Tab_Number_Components;
        } else if (isFileUpload) {
          ValidationTab.components = Validation_Tab_File_Multi_Components;
        } else if (!!field?.customDataTableGuid && field?.supportsMultiple) {
          ValidationTab.components = Validation_Tab_SelectBox_Components;
        } else if (isSubset) {
          ValidationTab.components = Validation_Tab_Subset_Components;
          if (field.subsetCollectionType === ReferenceFieldAPI.DataSetCollectionType.Number) {
            ValidationTab.components = [
              ...ValidationTab.components,
              Data_Set_Validation_Total
            ];
          }
        } else {
          ValidationTab.components = Validation_Tab_Table_Components;
        }
      } else if (isTextField || isTextArea) {
        ValidationTab.components = [
          Required_Setting,
          Custom_Error_Setting,
          field.supportsMultiple ? undefined : Min_Length_Setting,
          field.supportsMultiple ? undefined : Max_Length_Setting,
          field.supportsMultiple ? undefined : Min_Words_Setting,
          field.supportsMultiple ? undefined : Max_Words_Setting,
          field.supportsMultiple || isTextArea ? undefined : Regular_Expression_Setting,
          field.supportsMultiple || isTextArea || !!field.formatType ? undefined : Input_Mask_Setting
        ].filter((item) => !!item);
      }

      ValidationTab.components = [
        ...ValidationTab.components
      ];
      if (!isAddress) {
        ValidationTab.components = [
          ...ValidationTab.components,
          ...this.getCustomValidationComponents(customValidation)
        ];
      }
    }

    let DefaultValue: ComponentTabSetting;
    if (defaultValType === DefaultValType.Text) {
      DefaultValue = Default_Value_Setting;
    } else if (defaultValType === DefaultValType.Date) {
      DefaultValue = {
        ...Default_Value_Setting,
        type: 'date'
      };
    }

    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Label_Setting,
          isExternalApiField ? External_Api_Settings : undefined,
          isExternalApiField ? Display_Type_Setting : undefined,
          supportsHideLabel ? Hide_Label_Setting : undefined,
          supportsPlaceholder ? Placeholder_Setting : undefined,
          Description_Setting,
          supportsTooltip ? Tooltip_Setting : undefined,
          Tab_Index_Setting,
          isTable ? Rows_Per_Page_Setting : undefined,
          isTextField || isNumber ? Prefix_Setting : undefined,
          isTextField || isNumber ? Suffix_Setting : undefined,
          isTextArea && !field.isRichText ? Text_Area_Rows_Setting : undefined,
          supportsWordCount ? Word_Counter_Setting : undefined,
          supportsWordCount ? Character_Counter_Setting : undefined,
          supportsDisabled ? Disabled_Setting : undefined,
          isSelectBoxes || isRadio ? Inline_Layout_Setting : undefined,
          isSubset ? Num_Items_Before_Scroll_Setting : undefined,
          isCurrencyField && hasInternational ? Custom_Currency_Settings : undefined,
          isExternalApiField ? Require_External_Api_Response : undefined,
          isExternalApiField ? Visible_To_Applicants : undefined,
          isExternalApiField ? Visible_To_Managers : undefined,
          supportsFormulaBuilder ? Allow_Override_Calculated_Value : undefined,
          this.clientSettingsService.isBBGM ? Pull_From_Bbgm_Setting : undefined
        ].filter((val) => !!val)
      },
      [ComponentConfigTabType.Data]: supportsDataTab ?
        {
          components: [
            isNumber ? Decimal_Places_Setting : undefined,
            isNumber ? Require_Decimal_Setting : undefined,
            isDependentPicklist ? Dependent_Picklist_Settings : undefined,
            DefaultValue,
            isExternalApiField ? External_Api_Form_Field_Source : undefined,
            isExternalApiField ? External_Api_Data_Updates : undefined,
            !!field.customDataTableGuid && this.formFieldHelperService.doesTypeHaveOptions(field.type) ? Hidden_Cdt_Keys : undefined
          ].filter((val) => !!val)
        } :
        Empty_State_Tab,
      [ComponentConfigTabType.Validation]: ValidationTab,
      [ComponentConfigTabType.Api]: Api_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: [
          supportsClearWhenHidden ? Clear_When_Hidden_Setting : undefined,
          ...this.getConditionalLogicComponents(customConditional)
        ].filter((val) => !!val)
      },
      [ComponentConfigTabType.Set_Value]: supportsSetValueTab ?
        Set_Value_Tab :
        Empty_State_Tab,
      [ComponentConfigTabType.Calculated_Value]: supportsFormulaBuilder ?
        {
          components: [
            ...this.getCalculatedValueComponents(calculatedValue)
          ]
        } :
        Empty_State_Tab,
      [ComponentConfigTabType.Columns]: isTable ?
        {
          components: [
            Table_Component_Visible_Columns
          ]
        } :
        Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for employee sso component
   */
  getEmployeeSsoConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Label_Setting,
          Description_Setting
        ]
      },
      [ComponentConfigTabType.Data]: Empty_State_Tab,
      [ComponentConfigTabType.Validation]: Empty_State_Tab,
      [ComponentConfigTabType.Api]: Empty_State_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @param customValidation: old js custom validation logic stored on the component
   * @param calculatedValue: old js calculated value logic stored on the component
   * @returns config info for amount requested component
   */
  getAmountRequestedConfig (
    customConditional: string,
    customValidation: string,
    calculatedValue: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Label_Setting,
          Hide_Label_Setting,
          Description_Setting,
          Tab_Index_Setting,
          Disabled_Setting,
          Allow_Override_Calculated_Value,
          this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.HasInternational) ?
            Custom_Currency_Settings :
            undefined
        ].filter((val) => !!val)
      },
      [ComponentConfigTabType.Data]: {
        components: [
          Default_Value_Setting
        ].filter((val) => !!val)
      },
      [ComponentConfigTabType.Validation]: {
        components: [
          Required_Setting,
          Custom_Error_Setting,
          ...this.getCustomValidationComponents(customValidation)
        ]
      },
      [ComponentConfigTabType.Api]: Api_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Set_Value_Tab,
      [ComponentConfigTabType.Calculated_Value]: {
        components: [
          ...this.getCalculatedValueComponents(calculatedValue)
        ]
      },
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for in kind component
   */
  getInKindConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Label_Setting,
          Hide_Label_Setting,
          Description_Setting,
          Tab_Index_Setting,
          Disabled_Setting
        ]
      },
      [ComponentConfigTabType.Data]: {
        components: [
          In_Kind_Data_Settings
        ]
      },
      [ComponentConfigTabType.Validation]: {
        components: [
          In_Kind_Validation_Settings
        ]
      },
      [ComponentConfigTabType.Api]: Empty_State_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for care of / attention component
   */
  getCareOfConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Label_Setting,
          Description_Setting,
          Tab_Index_Setting
        ]
      },
      [ComponentConfigTabType.Data]: Empty_State_Tab,
      [ComponentConfigTabType.Validation]: Empty_State_Tab,
      [ComponentConfigTabType.Api]: Empty_State_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for decision component
   */
  getDecisionConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Label_Setting,
          Text_For_Approving_Setting,
          Text_For_Declining_Setting,
          Allow_Recused_Settings,
          Placeholder_Setting,
          Description_Setting,
          Tab_Index_Setting
        ]
      },
      [ComponentConfigTabType.Data]: Empty_State_Tab,
      [ComponentConfigTabType.Validation]: {
        components: [
          Required_Setting,
          Custom_Error_Setting
        ]
      },
      [ComponentConfigTabType.Api]: Empty_State_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Set_Value_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for designation component
   */
  getDesignationConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Label_Setting,
          Hide_Label_Setting,
          Placeholder_Setting,
          Description_Setting,
          Tab_Index_Setting,
          Disabled_Setting
        ]
      },
      [ComponentConfigTabType.Data]: Empty_State_Tab,
      [ComponentConfigTabType.Validation]: {
        components: [
          Required_Setting,
          Custom_Error_Setting
        ]
      },
      [ComponentConfigTabType.Api]: Api_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Set_Value_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for report field component
   */
  getReportFieldConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Label_Setting,
          Hide_Label_Setting,
          Placeholder_Setting,
          Description_Setting,
          Tab_Index_Setting,
          Display_Type_Setting
        ]
      },
      [ComponentConfigTabType.Data]: Report_Field_Data_Tab,
      [ComponentConfigTabType.Validation]: Empty_State_Tab,
      [ComponentConfigTabType.Api]: Empty_State_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @param customValidation: old js custom validation logic stored on the component
   * @param calculatedValue: old js calculated value logic stored on the component
   * @returns config info for recommended funding component
   */
  getRecommendedFundingConfig (
    customConditional: string,
    customValidation: string,
    calculatedValue: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Label_Setting,
          Hide_Label_Setting,
          Description_Setting,
          Tab_Index_Setting,
          Disabled_Setting
        ].filter((val) => !!val)
      },
      [ComponentConfigTabType.Data]: {
        components: [
          Default_Value_Setting
        ]
      },
      [ComponentConfigTabType.Validation]: {
        components: [
          Required_Setting,
          Custom_Error_Setting,
          ...this.getCustomValidationComponents(customValidation)
        ]
      },
      [ComponentConfigTabType.Api]: Api_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Set_Value_Tab,
      [ComponentConfigTabType.Calculated_Value]: {
        components: [
          ...this.getCalculatedValueComponents(calculatedValue)
        ]
      },
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for special handling component
   */
  getSpecialHandlingConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Label_Setting,
          Tab_Index_Setting,
          Require_Supporting_Document_Setting,
          Require_Reason_Setting
        ]
      },
      [ComponentConfigTabType.Data]: Empty_State_Tab,
      [ComponentConfigTabType.Validation]: Empty_State_Tab,
      [ComponentConfigTabType.Api]: Empty_State_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for content component
   */
  getContentConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: Empty_State_Tab,
      [ComponentConfigTabType.Data]: Empty_State_Tab,
      [ComponentConfigTabType.Validation]: Empty_State_Tab,
      [ComponentConfigTabType.Api]: Empty_State_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for columns component
   */
  getColumnsConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Column_Properties_Settings
        ]
      },
      [ComponentConfigTabType.Data]: Empty_State_Tab,
      [ComponentConfigTabType.Validation]: Empty_State_Tab,
      [ComponentConfigTabType.Api]: Api_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for field set component
   */
  getFieldSetConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Legend_Setting
        ]
      },
      [ComponentConfigTabType.Data]: Empty_State_Tab,
      [ComponentConfigTabType.Validation]: Empty_State_Tab,
      [ComponentConfigTabType.Api]: Api_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for panel component
   */
  getPanelConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: {
        components: [
          Title_Setting,
          Collapsible_Panel_Setting,
          Collapsed_Panel_Setting
        ]
      },
      [ComponentConfigTabType.Data]: Empty_State_Tab,
      [ComponentConfigTabType.Validation]: Empty_State_Tab,
      [ComponentConfigTabType.Api]: Api_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for table/grid layout component
   */
  getTableConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: Empty_State_Tab,
      [ComponentConfigTabType.Data]: Empty_State_Tab,
      [ComponentConfigTabType.Validation]: Empty_State_Tab,
      [ComponentConfigTabType.Api]: Api_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }

  /**
   *
   * @param customConditional: old js custom conditional logic stored on the component
   * @returns config info for well component
   */
  getWellConfig (
    customConditional: string
  ): Record<ComponentConfigTabType, ComponentTabOptions> {
    return {
      [ComponentConfigTabType.Display]: Empty_State_Tab,
      [ComponentConfigTabType.Data]: Empty_State_Tab,
      [ComponentConfigTabType.Validation]: Empty_State_Tab,
      [ComponentConfigTabType.Api]: Api_Tab,
      [ComponentConfigTabType.Conditional_Visibility]: {
        components: this.getConditionalLogicComponents(customConditional)
      },
      [ComponentConfigTabType.Set_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Calculated_Value]: Empty_State_Tab,
      [ComponentConfigTabType.Columns]: Empty_State_Tab
    };
  }
}
