import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AggregationService } from '@core/services/aggregation.service';
import { SpecialHandlingService } from '@core/services/special-handling.service';
import { TranslationService } from '@core/services/translation.service';
import { ApplicantFormForUI, ApplicationEditDetail, ApplicationForUi, ApplicationViewDetail, ApplicationViewPage, RecommendedFundingInfoForUI } from '@core/typings/application.typing';
import { BudgetFundingSource } from '@core/typings/budget.typing';
import { NonprofitDetail, OrganizationForInfoPanel } from '@core/typings/organization.typing';
import { ApplicationStatuses } from '@core/typings/status.typing';
import { ApplicantManagerService } from '@features/applicant/applicant-manager.service';
import { ApplicationFormService } from '@features/application-forms/services/application-forms.service';
import { ApplicationActionService } from '@features/application-manager/services/application-actions/application-actions.service';
import { AwardService } from '@features/awards/award.service';
import { BudgetService } from '@features/budgets/budget.service';
import { CommunicationsService } from '@features/communications/communications.service';
import { FormStatuses, FormTypes } from '@features/configure-forms/form.typing';
import { DashboardsService } from '@features/dashboards/dashboards.service';
import { EmployeeSSOFieldsService } from '@features/employee-sso-fields/employee-sso-fields.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { ProgramService } from '@features/programs/services/program.service';
import { EmailNotificationType } from '@features/system-emails/email.typing';
import { SystemTagsService } from '@features/system-tags/system-tags.service';
import { SystemTags } from '@features/system-tags/typings/system-tags.typing';
import { WorkflowService } from '@features/workflow/workflow.service';
import { TextFriendlySpecialCharCleaner } from '@yourcause/common/form-control-validation';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ApplicationViewResources } from './application-view.resources';
import { ApplicationViewState } from './application-view.state';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';

@AttachYCState(ApplicationViewState)
@Injectable({ providedIn: 'root' })
export class ApplicationViewService extends BaseYCService<ApplicationViewState> {

  constructor (
    private applicationViewResources: ApplicationViewResources,
    private logger: LogService,
    private i18n: I18nService,
    private translationService: TranslationService,
    private applicationFormService: ApplicationFormService,
    private employeeSSOFieldsService: EmployeeSSOFieldsService,
    private systemTagsService: SystemTagsService,
    private communicationService: CommunicationsService,
    private budgetService: BudgetService,
    private formHelperService: FormHelperService,
    private notifier: NotifierService,
    private aggregationService: AggregationService,
    private specialHandlingService: SpecialHandlingService,
    private applicationActionService: ApplicationActionService,
    private awardService: AwardService,
    private programService: ProgramService,
    private workflowService: WorkflowService,
    private applicantManagerService: ApplicantManagerService,
    private router: Router,
    private dashboardService: DashboardsService,
    private clientSettingsService: ClientSettingsService
  ) {
    super();
  }

  get applicationViewMap () {
    return this.get('applicationViewMap');
  }

  get applicationEditMap () {
    return this.get('applicationEditMap');
  }

  /**
   * Gets the application detail given the ID
   *
   * @param appId: Application ID
   * @returns the application detail
   */
  async getApplicationDetail (appId: number): Promise<ApplicationForUi> {
    const detail = await this.applicationViewResources.getApplication(appId);

    return {
      ...detail,
      specialHandling: this.specialHandlingService.getSpecialHandling(detail),
      amountRequestedForEdit: detail.currencyRequestedAmountEquivalent,
      currencyRequested: detail.currencyRequested ?? this.clientSettingsService.defaultCurrency,
      designation: detail.paymentDesignation
    };
  }

  /**
   * Resolves the Application View / Edit Page
   */
  async resolveApplicationView (id: number) {
    try {
      await this.workflowService.setMyWorkflowManagerRolesMap();
      await this.setApplicationViewMap(id);
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorLoadingApplication',
        {},
        'There was an error loading the application'
      ));
      this.router.navigateByUrl(this.dashboardService.homeRoute);
      
      return;
    }

    const application = this.applicationViewMap[id].application;
    if (application.charityId) {
      try {
        await this.setCsrData(id, application.charityId);
      } catch (e) {
        this.logger.error(e);
      }
    }
  }

  /**
   * Sets CSR Data for a given App
   *
   * @param appId: Application ID
   * @param charityId: Charity ID
   */
  async setCsrData (appId: number, charityId: number) {
    const csrData = await this.aggregationService.getCharityDonationsByYear(
      charityId
    );
    this.set('applicationViewMap', {
      ...this.applicationViewMap,
      [appId]: {
        ...this.applicationViewMap[appId],
        csrData
      }
    });
  }

  /**
   * Appends the permissions to the application
   *
   * @param app: Application
   * @returns the application with the permissions appended
   */
  addPermissionsForApplicationViewMap (app: ApplicationForUi) {
    const applicationActionFlags = this.applicationActionService.getApplicationActionFlags(app);
    const appWithPermissions = {
      ...app,
      ...applicationActionFlags
    };

    return appWithPermissions;
  }

  /**
   * Sets the Application View / Edit Map for a given application
   *
   * @param id: Application ID
   * @param isEdit: for edit view?
   * @param isNewApp: is this a new app?
   */
  async setApplicationViewMap (
    id: number,
    isEdit = false,
    isNewApp = false
  ) {
    const {
      applicationDetail,
      specialHandlingResponse,
      formsForAppView,
      applicantForms,
      awards,
      showSendToApplicant,
      employeeInfo,
      budgetDetail
    } = await this.fetchDataForApplicationView(id, isEdit);

    const organization = this.getOrgForInfoPanel(applicationDetail, specialHandlingResponse.nonprofitDetail);
    applicationDetail.defaultSpecialHandling = specialHandlingResponse.defaultAddress;
    applicationDetail.orgAddressString = specialHandlingResponse.orgAddressString;
    let budgetFundingSource: BudgetFundingSource;
    if (!!budgetDetail) {
      budgetFundingSource = budgetDetail.budgetFundingSources.find((budgetFS) => {
        return budgetFS.fundingSourceId === applicationDetail.fundingSourceId;
      });
    }
    const amountRequested = isNewApp ? null : applicationDetail.amountRequested;
    const inKindItems = applicationDetail.inKindItems.map((item) => {
      return  {
        itemIdentification: item.itemIdentification,
        count: item.count
      };
    });

    const application: ApplicationViewPage = {
      ...applicationDetail,
      amountRequested,
      employeeInfo,
      inKindItems
    };
    const primaryApplicant = this.adaptPrimaryApplicant(applicationDetail);
    if (isEdit) {
      const filteredForms = this.getFilteredApplicantFormsForEdit(applicantForms);
      const recordForEdit: ApplicationEditDetail = {
        application,
        showSendToApplicant,
        showSaveAsDraft: !showSendToApplicant &&
          application.applicationStatus === ApplicationStatuses.Draft,
        formsForEdit: filteredForms,
        defaultFormId: this.formHelperService.getDefaultForm(applicantForms).formId,
        nominee: applicationDetail.nominee,
        budget: budgetDetail,
        budgetFundingSource,
        awards,
        organization,
        primaryApplicant,
        program: this.programService.programMap[applicationDetail.programId]
      };
      this.set('applicationEditMap', {
        ...this.applicationEditMap,
        [id]: recordForEdit
      });
    } else {
      const recordForView: ApplicationViewDetail = {
        application,
        formsForAppView,
        nominee: applicationDetail.nominee,
        formsForEdit: applicantForms,
        csrData: null,
        budget: budgetDetail,
        budgetFundingSource,
        awards,
        organization,
        primaryApplicant,
        program: this.programService.programMap[applicationDetail.programId]
      };
      this.set('applicationViewMap', {
        ...this.applicationViewMap,
        [id]: recordForView
      });
    }
  }

  /**
   * Get Filtered Applicant Forms For Edit
   * 
   * @param applicantForms: Applicant Forms to Filter
   * @returns the filtered forms to display on application edit page
   */
  getFilteredApplicantFormsForEdit (
    applicantForms: ApplicantFormForUI[]
  ) {
    return applicantForms.filter((form) => {
      const isEligibility = form.formTypeId ===  FormTypes.ELIGIBILITY;
      const isRouting = form.formTypeId === FormTypes.ROUTING;
      const isNotSent = form.formStatus === FormStatuses.NotSent;

      return !isEligibility && !isRouting && !isNotSent;
    });
  }

  /**
   * 
   * @param id: Application ID
   * @param isEdit: Is this for edit?
   * @returns application data
   */
  async fetchDataForApplicationView (
    id: number,
    isEdit: boolean
  ) {
    const viewTranslations = this.translationService.viewTranslations;
    const programTranslationMap = viewTranslations.Grant_Program;
    let applicationDetail = await this.getApplicationDetail(id);
    const programTranslation = programTranslationMap[applicationDetail.programId];
    applicationDetail.programName = programTranslation ?
      programTranslation.Name :
      applicationDetail.programName;
    applicationDetail.designation = TextFriendlySpecialCharCleaner(
      applicationDetail.paymentDesignation
    );
    applicationDetail.specialHandling = {
      name: applicationDetail.specialHandlingName || '',
      address1: applicationDetail.specialHandlingAddress1 || '',
      address2: applicationDetail.specialHandlingAddress2 || '',
      city: applicationDetail.specialHandlingCity || '',
      state: applicationDetail.specialHandlingStateProvinceRegion || '',
      postalCode: applicationDetail.specialHandlingPostalCode || '',
      country: applicationDetail.specialHandlingCountry || '',
      notes: applicationDetail.specialHandlingNotes || '',
      reason: applicationDetail.specialHandlingReason || '',
      fileUrl: applicationDetail.specialHandlingFileUrl || ''
    };
    applicationDetail = this.addPermissionsForApplicationViewMap(applicationDetail);
    await this.applicantManagerService.setApplicantProfileDetails(applicationDetail.applicantId);
    const [
      specialHandlingResponse,
      formsForAppView,
      applicantForms,
      awards,
      nomination,
      showSendToApplicant,
      employeeInfo,
      budgetDetail
    ] = await Promise.all([
      // specialHandlingResponse
      this.specialHandlingService.getDefaultSpecialHandling(
        applicationDetail.organizationId,
        applicationDetail.nonprofitGuid,
        applicationDetail.organizationName,
        this.applicantManagerService.applicantMap[applicationDetail.applicantId].applicant
      ),
      // formsForAppView
      !isEdit ?
        this.applicationFormService.getAllApplicationForms(
          applicationDetail.applicationId,
          applicationDetail.isApplicationInClientUserWorkflowLevel,
          applicationDetail.applicationStatus
        ) :
        null,
      // applicant forms
      this.applicationFormService.getApplicantFormsForApplication(
        applicationDetail.applicationId,
        applicationDetail.applicationStatus
      ),
      // awards
      this.awardService.getAwards(applicationDetail.applicationId),
      // nomination
      applicationDetail.nominationApplicationId ?
        this.getApplicationDetail(
          applicationDetail.nominationApplicationId
        ) :
        null,
      // showSendToApplicant
      isEdit ?
        this.getShowSendToApplicant(
          applicationDetail.applicationId,
          applicationDetail.applicantCanReceiveEmails,
          applicationDetail.applicationStatus === ApplicationStatuses.Draft
        ) :
        null,
      // employeeInfo
      this.employeeSSOFieldsService.getEmployeeSSOFieldsForApp(applicationDetail.applicationId),
      // budget detail
      !!applicationDetail.budgetId ?
        this.budgetService.getBudgetDetail(applicationDetail.budgetId) :
        null,
      this.systemTagsService.fetchTagsForRecord(
        SystemTags.Buckets.Applicant,
        applicationDetail.applicantId
      ),
      // program
      this.programService.getProgram('' + applicationDetail.programId)
    ]);

    return {
      applicationDetail,
      specialHandlingResponse,
      formsForAppView,
      applicantForms,
      awards,
      nomination,
      showSendToApplicant,
      employeeInfo,
      budgetDetail
    };
  }

  /**
   * Returns the organization info for the panel
   *
   * @param applicationDetail: Application Detail
   * @param nonprofitDetail: Nonprofit Detail
   * @returns the organization info for panel
   */
  getOrgForInfoPanel (
    applicationDetail: ApplicationForUi,
    nonprofitDetail: NonprofitDetail
  ): OrganizationForInfoPanel {
    return applicationDetail.organizationId ? {
      id: applicationDetail.organizationId,
      guid: applicationDetail.nonprofitGuid,
      name: applicationDetail.organizationName,
      image: applicationDetail.orgnizationImageUrl,
      canUpdate: false,
      nameIsLink: true,
      imageUrl: applicationDetail.orgnizationImageUrl,
      address: null,
      isPrivateOrg: applicationDetail.isPrivateOrg,
      addressString: applicationDetail.organizationAddress,
      identification: applicationDetail.organizationIdentification,
      eligibleForGivingStatusId: applicationDetail.organizationEligibleForGivingStatus,
      isInternational: applicationDetail.orgIsInternational,
      parentGuid: nonprofitDetail?.parentNonprofitGuid,
      parentName: nonprofitDetail?.parentNonprofitName,
      registrationAuthorityName: nonprofitDetail?.registrationAuthority?.registrationAuthorityName,
      phone: nonprofitDetail?.displayNumber
    } : null;
  }

  /**
   * Returns the adapted primay applicant info
   *
   * @param applicationDetail: Application Detail
   * @returns the adapted primary applicant
   */
  adaptPrimaryApplicant (applicationDetail: ApplicationForUi) {
    return {
      id: applicationDetail.applicantId,
      firstName: applicationDetail.applicantFirstName,
      lastName: applicationDetail.applicantLastName,
      email: applicationDetail.applicantEmail,
      canManageApplicants: true,
      canReceiveEmails: applicationDetail.applicantCanReceiveEmails,
      isApplicationOwner: true,
      phoneNumber: applicationDetail.applicantPhoneNumber,
      addressString: applicationDetail.applicantAddress
    };
  }

  /**
   * Returns whether we should show the send to applicant action on edit application view
   *
   * @param applicationId: Application ID
   * @param notifyApplicant: Notify applicant setting
   * @param isDraft: is this a draft?
   * @returns
   */
  async getShowSendToApplicant (
    applicationId: number,
    notifyApplicant: boolean,
    isDraft: boolean
  ): Promise<boolean> {
    if (isDraft && notifyApplicant) {
      let showSendApplication = true;
      const comms = await this.communicationService.getCommunicationsForApplication(
        applicationId,
        true
      );
      const filtered = comms.map((groupedComms) => {
        return groupedComms[0];
      }).filter((comm) => {
        return comm.emailNotificationType === EmailNotificationType.ApplicantAddedToApplication;
      });
      await Promise.all(filtered.map(async (comm) => {
        const detail = await this.communicationService.getEmailDetails(
          comm.joinCommunicationId
        );
        if (detail.applicationId === +applicationId) {
          showSendApplication = false;
        }
      }));

      return showSendApplication;
    }

    return false;
  }

  /**
   * Returns whether the current user is allowed to view the given app
   *
   * @param id: Application ID
   * @returns if the current user is allowed to view
   */
  canViewApplication (id: number) {
    return this.applicationViewResources.canViewApplication(id);
  }

  /**
   * Triggers the Approve Modal to Open
   */
  triggerApproveModal () {
    this.triggerChange('triggerApproveModal');
  }

  /**
   * Triggers the Activity Tab to Update
   */
  triggerActivityTabUpdate () {
    this.triggerChange('triggerActivityTabUpdate');
  }

  /**
   * Gets reviewer recommended funding info for the app
   *
   * @param applicationId: app ID
   * @returns recommended funding info for the app
   */
  async getReviewerRecommendedFundingInfo (
    applicationId: number
  ): Promise<RecommendedFundingInfoForUI[]> {
    const res = await this.applicationViewResources.getReviewerRecommendedFundingInfo(
      applicationId
    );

    return res.reviewerRecommendations.map<RecommendedFundingInfoForUI>((item) => {
      return {
        recommendedFundingAmount: item.reviewerRecommendedFundingAmount,
        decision: item.decision,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        workflowLevelName: item.workflowLevelName
      };
    });
  }

  /**
   * Gets the application audit trail
   *
   * @param applicationId: Application ID
   * @returns the activity audit trail
   */
  getApplicationAuditTrail (
    applicationId: number
  ) {
    return this.applicationViewResources.getApplicationAuditTrail(applicationId);
  }

  getApplicationViewBasePath (
    isEdit: boolean,
    isNomination: boolean,
    id: number
  ) {
    if (isEdit) {
      return `/management/manage-${isNomination ? 'nominations' : 'applications'}/${isNomination ? 'nomination' : 'application'}/${id}`;
    }

    return `/management/${isNomination ? 'nomination-' : 'application-'}view/${id}`;
  }

  /**
   * Get the Applications Title
   *
   * @param orgName: Organization Name
   * @param applicantName: Applicant Name
   * @param isNomination: Is Nomination?
   * @param isMasked: Is Masked?
   * @param canViewMaskedApplicantInfo: Can user view masked applicant info?
   * @param showMaskedApplicant: Are we showing the masked applicant?
   * @returns the application "title"
   */
  getAppTitle (
    orgName: string,
    applicantName: string,
    isNomination: boolean,
    isMasked: boolean,
    canViewMaskedApplicantInfo: boolean,
    showMaskedApplicant: boolean
  ) {
    let title = '';
    const applicantMaskedText = this.i18n.translate(
      isNomination ?
        'GLOBAL:textNominatorNameIsMasked' :
        'GLOBAL:textApplicantNameIsMasked',
      {},
      isNomination ?
        'Nominator name is masked' :
        'Applicant name is masked'
    );
    if (orgName) {
      if (!isMasked || showMaskedApplicant) {
        title = this.i18n.translate(
          isNomination ?
            'APPLY:textApplicationNominatingDynamic' :
            'APPLY:textApplicationOnBehalfOfDynamic',
          {
            name: applicantName,
            organization: orgName
          },
          isNomination ?
            '__name__ nominating __organization__' :
            '__name__ on behalf of __organization__'
        );
      } else {
        title = orgName + ' (' + applicantMaskedText + ')';
      }
    } else {
      if (
        canViewMaskedApplicantInfo &&
        (!isMasked || showMaskedApplicant)
      ) {
        title = applicantName;
      } else {
        title = applicantMaskedText;
      }
    }

    return title;
  }
}
